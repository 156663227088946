import request from "../utils/request";
//新建专利分类
export const creatType = data => {
  return request({
    method: 'POST',
    url: '/system/patent/addPatentCategory',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除专利分类
export const delType = data => {
  return request({
    method: 'POST',
    url: '/system/patent/deletePatentCategory',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取专利分类详情
export const getTypeDetail = data => {
  return request({
    method: 'POST',
    url: '/system/patent/getDetailPatentCategory',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取专利报告分类列表
export const getTypeList = data => {
  return request({
    method: 'POST',
    url: '/system/patent/getPatentCategory',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改专利类型
export const changeType = data => {
  return request({
    method: 'POST',
    url: '/system/patent/updatePatentCategory',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
// ===========分割线==========报告内容
//新建证书
export const createPatent = data => {
  return request({
    method: 'POST',
    url: '/system/patent/addPatent',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除证书
export const delpatent = data => {
  return request({
    method: 'POST',
    url: '/system/patent/deletePatent',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取证书详情
export const getPatentDetail = data => {
  return request({
    method: 'POST',
    url: '/system/patent/getDetailPatent',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取证书内容列表
export const getPatentList = data => {
  return request({
    method: 'POST',
    url: '/system/patent/getPatent',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改证书
export const changePatent = data => {
  return request({
    method: 'POST',
    url: '/system/patent/updatePatent',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//获取专利报告分类列表

export const getTypeLi = data => {
  return request({
    method: 'get',
    url: '/system/patent/chooseCategory',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}