<template>
  <div class="all">
    <!-- 新建按钮 -->
    <el-button class="new" @click="newbtn">+ 新建</el-button>
    <!-- 表格内容展示 -->
    <el-table :data="tableList">
      <el-table-column type="selection" align="center">
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="bannerName" label="Banner名称">
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="updateTime" label="修改时间">
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="adminName" label="创建人">
      </el-table-column>

      <el-table-column header-align="center" align="center" prop="status" label="状态">
        <template slot-scope="{row}">
          {{row.status==1? '上线':'下线'}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="prop" label="操作">
        <template slot-scope="{row}">
          <el-button class="cha1" @click="detailIn(row.id)">查看</el-button>
          <el-button class="edit1" @click="edit(row.id)">修改</el-button>
          <el-button class="del1" @click="del(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="title" :visible.sync="banner" @close="closeDialog">
      <el-form ref="form" :model="formD" label-width="120px" :rules="rules">
        <el-form-item label="Banner名称 : " prop="bannerName">
          <el-input v-model="formD.bannerName" placeholder="请输入Banner名称"></el-input>
        </el-form-item>
        <el-form-item prop="pcPic" label="Banner图 : " class="upload">
          <span>PC端 </span>
          <span v-if="showimg">
            <img :src="formD.pcPic" alt="" class="formimg" v-if="title=='修改Banner'">
          </span>
          <el-upload accept=".png,.jpg,.jpeg" :headers="headers"
            action="https://api.mz-biotech.com/api/file/uploadFiles" list-type="picture-card" :limit="1"
            :on-success="uploadSuccess" :before-upload="beforeAvatarUpload" ref="uploaddemo" :on-remove="removeImgs">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                  <i class="el-icon-zoom-in"></i>
                </span>

                <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
            <div slot="tip" class="el-upload__tip">（上传说明：尺寸最小 1920*1024，图片格式JPG、JPEG、PNG，单个文件大小不能超过20M）</div>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
          <!-- <el-upload accept=".png,.jpg,.jpeg" :headers="headers" class="upload-demo1"
            action="http://api.mz-biotech.com/api/file/uploadFiles" :on-success="uploadSuccess"   :before-upload="beforeAvatarUpload" multiple name="file"
            :limit="1" :show-file-list="true"   ref="uploaddemo"
            :on-remove="removeImgs">
            <img src="~@/assets/2.png" alt="">

          </el-upload> -->

        </el-form-item>
        <el-form-item prop="wapPic" class="upload">
          <span>Wap端</span>
          <span v-if='showimgw'><img :src="formD.wapPic" alt="" v-if="title=='修改Banner'" class="formimg">
          </span>
          <el-upload accept=".png,.jpg,.jpeg" :headers="headers"
            action="https://api.mz-biotech.com/api/file/uploadFiles" list-type="picture-card" :limit="1"
            :on-success="upload" ref="uploaddemo1" :on-remove="removeImgs">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                  <i class="el-icon-zoom-in"></i>
                </span>

                <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove1(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
            <div slot="tip" class="el-upload__tip">（上传说明：尺寸最小 1920*1024，图片格式JPG、JPEG、PNG，单个文件大小不能超过20M）</div>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
          <!-- <el-upload accept=".png,.jpg,.jpeg" :headers="headers" class="upload-demo1"
            action="http://api.mz-biotech.com/api/file/uploadFiles" :on-success="upload" multiple name="file" :limit="1"
            :show-file-list="true"   ref="uploaddemo1" :on-remove="removeImgs">
            <img src="~@/assets/2.png" alt="">

          </el-upload> -->
        </el-form-item>

        <el-form-item label="状态 : " prop="status" class="status">
          <el-radio-group v-model="formD.status" @change="statuschange">
            <el-radio label="1">上线</el-radio>
            <el-radio label="0">下线</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="btn">
          <el-button class="cancelBtn" @click="cancel">取消</el-button>
          <el-button class="savebtn" @click="savebanner" :disabled="savebtn">保存</el-button>
        </el-form-item>

      </el-form>

    </el-dialog>
    <el-dialog title="Banner详情" :visible.sync="lookbanner" class="bannerdetail" @close="detailclose">
      <el-form ref="form" :model="formD" label-width="120px">
        <el-form-item label="Banner名称 : ">
          <span class="neirong">{{formD.bannerName}}</span>
        </el-form-item>
        <el-form-item label="Banner图 : ">
          <div> <span class="img">PC端</span><img :src="formD.pcPic" alt="" class="chaimg"></div>
          <div> <span class="img">wap端</span><img :src="formD.wapPic" alt="" class="chaimg1"></div>
        </el-form-item>

        <el-form-item label="状态 : ">
          <span class="neirong">{{formD.status==1? '上线':'下线'}}</span>
        </el-form-item>
      </el-form>

    </el-dialog>
    <el-pagination background layout="prev, pager, next,total" :total="page.total" :page-size="page.pageSize"
      :current-page.sync="page.pageNum" @current-change="handleGetList">
    </el-pagination>
  </div>
</template>

<script>
import store from '@/store'

import { getbannerlist, getbannerdetail, createbanner, delbanner, changebanner, checkshangxian } from '../../../api/core'
export default {
  data () {
    return {
      savebtn: false,//防抖使用
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      //控制编辑和新建弹框的显示隐藏
      banner: false,
      //控制查看的弹框显示隐藏
      lookbanner: false,
      //banner表格的数据
      tableList: [],
      //编辑图片的
      showimg: true,
      showimgw: true,
      //每一条banner的数据
      formD: {
        adminId: 0,//	创建人id
        adminName: '',//	创建人名
        bannerName: '',//	banner名
        bannerType: 5,//类型：0请选择 1核心技术 2产品中心 3解决方案 4荣誉资质 5专利报告 6关于我们 7新闻活动 10合作案例

        pcPic: '',//	pc端图片
        status: null,//	状态：0下线 1上线
        wapPic: '',//	wap端图片

      },

      orderNum: null,//排序的长度
      //弹框的标题
      title: '新建Banner',
      //表格的页码
      page: {
        total: null,
        pageNum: 1,
        pageSize: 10,
        bannerType: 5
      },





      rules: {
        bannerName: [
          { required: true, message: 'Banner名称不能为空', trigger: 'blur' },
          { max: 16, message: '长度不多于16个字符', trigger: 'change' }
        ],
        pcPic: [
          { required: true, message: '电脑端图片不能为空', trigger: 'change' }
        ],
        wapPic: [
          { required: true, message: '移动端图片不能为空', trigger: 'change' }
        ],

        status: [
          { required: true, message: '状态不能为空', trigger: 'change' }
        ]

      }
    }
  },
  computed: {
    headers () {

      return {
        "Authorization": store.getters.token
      }

    }
  },
  created () {
    this.getbannerlist()
  },
  methods: {
    //图片预览
    handleRemove () {
      this.$refs.uploaddemo.clearFiles();
    },
    handleRemove1 () {
      this.$refs.uploaddemo1.clearFiles();
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //页码控制
    handleGetList () {
      this.tableList = []
      this.getbannerlist()
    },
    // 处理时间
    gettime (timestamp, gettype) {
      // console.log(timestamp, 'timestamp')
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      if (gettype == "yyddmm") {
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10 ?
            "0" + (date.getMonth() + 1) :
            date.getMonth() + 1) + "-";
        var D = date.getDate() < 10 ? "0" + date.getDate() + ' ' : date.getDate() + " ";
        var h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
        var m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":"
        var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
      }
      // 算时间差
      if (gettype == "getcha") {
        // 两个时间戳相差的秒数
        var usedTime = timestamp * 1000;
        // console.log(usedTime,'usedTime')
        var hours = Math.floor(usedTime / (3600 * 1000));
        // var leave1 = usedTime % (3600 * 1000);
        // var minutes = Math.floor(leave1 / (60 * 1000))
      }
      return {
        yymmdd: Y + M + D + h + m + s,
        timecha: hours,
      };
    },
    async getbannerlist () {
      const { count, obj } = await getbannerlist(this.page)
      obj.map(item => {
        item.updateTime = (this.gettime(item.updateTime, "yyddmm").yymmdd)
      })
      this.page.total = count

      this.tableList = obj


    },
    //点击新建按钮
    newbtn () {
      this.banner = true
      this.title = '新建Banner'

    },
    //点击修改按钮
    async edit (bannerId) {

      const res = await getbannerdetail({ bannerId })
      res.status = res.status + ''
      this.formD = res

      this.title = '修改Banner'

      this.showimg = true
      this.showimgw = true
      this.banner = true

    },
    //点击查看按钮
    async detailIn (bannerId) {
      const res = await getbannerdetail({ bannerId })

      console.log(res);
      this.formD = res
      this.lookbanner = true

    },
    //点击删除按钮
    async del (bannerId) {
      await this.$confirm('是否确认删除,删除后的数据将不能再恢复!')
      await delbanner({ bannerId })
      this.getbannerlist()
    },
    //本地文件上传成功时-展示图

    uploadSuccess (file) {
      console.log(file);
      this.formD.pcPic = file.data
    },
    //本地文件上传成功时-检测报告文件

    upload (file) {
      this.formD.wapPic = file.data
    },
    // 点击保存编辑或新建Banner
    async savebanner () {
      await this.$refs.form.validate()
      this.savebtn = true
      setTimeout(() => this.savebtn = false, 2000)

      if (this.title == '新建Banner') {
        console.log(this.formD);
        await createbanner(this.formD)
        this.$message.success('新建Banner成功')
        this.getbannerlist()
        this.closeDialog()
      } else if (this.title = '修改Banner') {

        await changebanner(this.formD)
        this.$message.success('修改Banner成功')
        this.getbannerlist()
        this.closeDialog()
      }
    },
    //关闭弹窗x
    closeDialog () {
      this.$refs.form.resetFields()
      this.formD = {}
      this.removeImgs()
      // this.formD.pcPic = ''
      // this.formD.wapPic = ''
      // this.formD.forwardHead = ''
      this.banner = false
    },
    //点击取消时
    cancel () {
      this.closeDialog()
    },
    //当页面选择框改变时
    async selchange (v) {
      const res = await getproductType({ forwardHead: v })
      // res.map((item, i) => {

      //   this.producttype.push({ value: item.id, label: item.name })
      // })
      this.producttype = res
    },
    //当页面内容选择框改变时
    selch (v) {
      this.producttype.map(item => {
        if (item.name == v) {
          this.formD.forwardContentId = item.id
        }
      })
    },


    //删除pc的图
    delimgPC () {
      this.showimg = false
    },
    //删除wap的图
    delimgWAP () {
      this.showimgw = false
    },
    //查看详情弹框关闭
    detailclose () {
      this.lookbanner = false
    },
    //文件上传前限制类型和大小
    beforeAvatarUpload (file) {
      // let typeArray = ['image/jpg', 'image/jpeg', 'image/png']

      // const isJPG = typeArray.indexOf(file.raw.type);
      const isLt2M = file.size / 1024 / 1024 < 20;

      // if (isJPG === -1) {
      //   this.$message.error('上传图片只能是 JPG,JPEG,PNG 格式!');
      // }
      if (!isLt2M) {

        this.$message.error('上传图片大小不能超过 20MB!');
      }
      return isLt2M;
    },
    //清除已上传的图片列表
    removeImgs () {
      this.$refs.uploaddemo.clearFiles();
      this.$refs.uploaddemo1.clearFiles()
    },
    //状态改变判断上线
    async statuschange () {
      const res = await checkshangxian({ bannerType: 5 })
      if (!res && this.formD.status == 1) {
        this.formD.status = 0
        this.$confirm('上线条数已达上限,请下线其他banner再进行该操作!')

      }

    },

  }
}
</script>

<style lang="scss" scoped>
.new {
  margin-right: 100%;
}
.new.el-button {
  display: inline-block;
  width: 100px;
  height: 32px;
  background: #4a71af;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 6px;
  letter-spacing: 2px;
  margin-top: 24px;
}

.upload-demo1 {
  position: relative;
  top: -28px;
  left: 50px;
}
::v-deep .el-upload-list__item {
  width: 50%;
  position: absolute;
  top: 0;
  left: 35%;
}
.upload {
  text-align: left;
}
.paixu {
  text-align: left;
  ::v-deep .el-input__inner {
    width: 80px;
    height: 40px;
    background: #ffffff;
    // box-shadow: inset 0px 1px 3px 0px rgba(74, 113, 175, 1);
    border-radius: 12px;
    border: 2px solid rgba(74, 113, 175, 1);
    color: rgba(74, 113, 175, 1);
  }
}
.status {
  text-align: left;
}

.btn {
  .cancelBtn {
    margin-right: 50px;
    width: 110px;
    height: 40px;
    background: #ffffff;
    border-radius: 12px;
    border: 2px solid #4a71af;
    ::v-deep span {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4a71af;
      line-height: 10px;
    }
  }
  .savebtn {
    width: 110px;
    height: 40px;
    background: #4a71af;
    border-radius: 12px;
    ::v-deep span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 10px;
    }
  }
}
::v-deep .el-form-item__label {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #273352;
  text-align: end;
  line-height: 38px;
  letter-spacing: 1px;
}

::v-deep .el-input__inner {
  width: 300px;
  height: 40px;
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid rgba(74, 113, 175, 1);
  color: rgba(74, 113, 175, 1);
}

.formimg {
  vertical-align: top;
  position: relative;
  left: 20px;
  width: 220px;
  height: 144px;
}
.delimg {
  position: relative;
  top: -20px;
  right: -300px;
  display: block;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4a71af;
  line-height: 17px;
  margin-bottom: 20px;
  text-decoration: underline;
}
.chaimg {
  vertical-align: top;
  position: relative;
  left: 27px;
  margin-bottom: 20px;
  width: 200px;
  height: 150px;
}
.chaimg1 {
  vertical-align: top;
  position: relative;
  left: 15px;
  margin-bottom: 20px;
  width: 200px;
  height: 150px;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.bannerdetail {
  ::v-deep .el-form-item__label {
    width: 104px;
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #273352;
    line-height: 42px;
    letter-spacing: 1px;
  }
  // ::v-deep .el-form-item__content {
  //   text-align: left;
  // }
  .neirong {
    width: 240px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4a71af;
    line-height: 20px;
    letter-spacing: 1px;
  }
  ::v-deep .el-button {
    width: 155px;
    height: 40px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #4a71af;
    font-weight: 500;
    color: #4a71af;
  }
  .orderd {
    display: inline-block;
    width: 40px;
    height: 40px;
    color: #4a71af;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #4a71af;
    text-align: center;
  }
  .img {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #273352;
    letter-spacing: 1px;
  }
}
.cha1 {
  width: 68px;
  height: 28px;
  background: #4a71af;
  border-radius: 8px;
  border: 0;
  line-height: 2px;
  color: #fff;
}
.edit1 {
  width: 68px;
  height: 28px;
  background: #16b394;
  color: #fff;
  line-height: 2px;
  border: 0;

  border-radius: 8px;
}
.del1 {
  width: 68px;
  height: 28px;
  background: #e25858;
  border-radius: 8px;
  color: #fff;
  line-height: 2px;
  border: 0;
}
::v-deep .el-table__header-wrapper {
  margin-top: 15px;
  // width: 1610px;
  height: 60px;
  line-height: 35px;
  background: #f2f2f2;
  border-radius: 20px 20px 0px 0px;

  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  th.el-table__cell {
    background: #f2f2f2;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
  }
}
.el-table__cell {
  width: 48px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #4a4a4a;
  line-height: 17px;
}
.tiaozhuan {
  ::v-deep .el-input__inner {
    width: 180px;
    height: 40px;
    margin-right: 10px;
    background: #ffffff;
    border-radius: 12px;
    border: 2px solid rgba(74, 113, 175, 1);
    color: rgba(74, 113, 175, 1);
  }
  ::v-deep .el-input__suffix {
    position: absolute;
    left: 140px;
  }
}
::v-deep .el-upload-list__item {
  position: relative;
  top: -130px;
  left: 260px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #4a71af;
}
::v-deep .el-upload-list__item {
  top: 0;
  left: 0;
  width: 41%;
  height: 100%;
}
::v-deep
  ::v-deep
  .el-upload-list--picture-card
  .el-upload-list__item-thumbnail {
  height: 100%;
}
</style>